.ns-sidebar {
  .tabs-wrapper {
    height: 100%;

    .tabs-buttons {
      margin-bottom: -1px;

      .tab {
        min-width: 100px;
        padding: 10px 15px;
        font-weight: bold;
        font-size: 17px;
        letter-spacing: 0.05em;

        &.active {
          background: #FF4ADA;

          border-bottom: 1px solid #FF4ADA;
        }
      }
    }

    .tab-content {
      height: calc(100% - 46px);
      padding: 40px 20px;

      .small-link {
        margin-left: auto;
        display: block;
        margin-bottom: 10px;
      }

      .no-nfts {
        p {
          font-size: 17px;
          text-align: center;
          padding: 110px 70px;
          background: rgb(102 100 100 / 13%);
        }
      }
    }
  }
}