@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --text-primary: rgba(0, 0, 0, 0.95);
  --text-secondary: rgba(0, 0, 0, 0.6);
  --bg-primary: rgb(255, 255, 255);
  --bg-secondary: rgba(255, 255, 255, 0.98);
}

.dark {
  --text-primary: rgba(255, 255, 255, 0.95);
  --text-secondary: rgba(255, 255, 255, 0.6);
  --bg-primary: rgb(17, 17, 17);
  --bg-secondary: rgba(23, 25, 35, 0.98);
}

.pattern-bg {
  @apply bg-light-bg dark:bg-dark-bg;
  opacity: 0.8;
}


// Font Declarations
@font-face {
  font-family: 'InterRegular';
  src: url('../../public/fonts/Inter/Inter-Regular.ttf'),
    url('../../public/fonts/Inter/Inter-Regular.ttf');
}

@font-face {
  font-family: 'RoganRegular';
  src: url('../../public/fonts/Rogan/Rogan-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'RoganBold';
  src: url('../../public/fonts/Rogan/Rogan-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'InterBold';
  src: url('../../public/fonts/Inter/Inter-Bold.ttf'),
    url('../../public/fonts/Inter/Inter-Bold.ttf');
}

.inter-regular {
  font-family: 'InterRegular' !important;
}

.inter-bold {
  font-family: 'InterBold' !important;
}

@layer base {
  body {
    @apply bg-light-bg dark:bg-dark-bg text-light-text dark:text-dark-text;
    font-family: InterRegular, sans-serif;
  }

  h1, h2, h3, h4, h5, h6 {
    @apply font-['RoganRegular'];
  }
}

@layer components {
  .packs-widget {
    @apply bg-light-bg-secondary dark:bg-dark-bg-secondary 
           border border-light-border dark:border-dark-border 
           rounded-xl p-5 shadow-lg;
  }
}

[class*='page-content'] {
  margin-top: 80px;
}
