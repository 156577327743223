.home-page-timer {
  width: fit-content;
  padding: 2px 10px;
  border-radius: 5px;
  color: #2e2d25;
  background-color: #fff;
  .timer {
    display: flex;
    gap: 4px;
  }
}

body.home-page {
  font-family: InterRegular, sans-serif;
  background: var(--bg-primary);
  color: var(--text-primary);

  .pattern-bg {
    background-color: var(--bg-secondary);
    opacity: 0.8;
    
    .dark & {
      background-color: rgba(0, 0, 0, 0.9);
    }
  }

  #container {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
  }

  .inner-content {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
  }

  #header {
    display: flex;
    align-content: center;
    background: var(--bg-primary);
  }

  .homefooter {
    display: flex;
    justify-content: space-between;
    padding: 2rem;
    margin-top: 3rem;
    border-top: 1px solid var(--border-color);

    .copyright {
      h1 {
        color: inherit;
      }
    }

    #line {
      margin-left: 10px;
      margin-right: 10px;
      color: inherit;
    }

    .footerlist {
      display: flex;
      margin-bottom: 1rem;
      flex-direction: row;
      justify-content: space-between;
      width: 140px;
      font-size: 1.5rem;

      button {
        background: transparent;
        
        a {
          color: inherit;
          
          &:hover {
            color: var(--primary);
          }
        }
      }

     

    }
   
  }

  

  // Update scrollbars
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  ::-webkit-scrollbar-track {
    background: var(--bg-secondary);
  }

  ::-webkit-scrollbar-thumb {
    background: var(--text-secondary);
    border-radius: 4px;

    &:hover {
      background: var(--text-primary);
    }
  }

  @media only screen and (max-width: 777px) {
    .homefooter {
      padding: 0;
      margin-bottom: 1rem;
      flex-direction: column-reverse;
      align-items: center;

      h1 {
        text-align: center;
        font-size: 0.8rem;
      }

      .footerlist {
        margin-bottom: 1rem;
        margin-top: 2rem;
        font-size: 1.3rem;
      }
    }
  }

  footer{
    &.light {
      border-top: 1px solid rgba(0, 0, 0, 0.1);
    }
  
    &.dark {
      border-top: 1px solid rgba(255, 255, 255, 0.1);
    }
  }
}