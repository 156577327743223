.skin-selected {
  background: #FFBE4D;
  color: #1A191C !important;
}


.explorer-link {
  color: #FFBE4D;
  text-decoration: underline;
  cursor: pointer;
}

.amber-header {
  background: #2E2514;
}


.star-atlas-bg {
  background-color: #1D1D25;
}



.rogan-regular {
  font-family: 'RoganRegular', sans-serif;
}

.rogan-bold {
  font-family: 'RoganBold', sans-serif;
}


button:hover,
.token:hover {
  opacity: 0.7;
}


@media only screen and (max-width: 677px) {
  .text-xl {
    font-size: 1rem !important;
  }

  .text-lg {
    font-size: 0.8rem !important;
  }

  .text-md {
    font-size: 0.8rem !important;
  }

  button.py-3,
  .token.py-3 {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
  }
}

@media only screen and (max-width: 405px) {
  .text-xl {
    font-size: 0.8rem !important;
  }

  .text-lg {
    font-size: 0.7rem !important;
  }

  .text-md {
    font-size: 0.7rem !important;
  }


}